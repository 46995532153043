import "flowbite/dist/flowbite.min.js";
import "flowbite/dist/flowbite.min.css";
import daisyui from "daisyui";

// Import Swiper after Flowbite
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';;


document.addEventListener("DOMContentLoaded", function() {
  // Fungsi untuk mengubah jarak dengan kombinasi 7vh dan 2rem
  function scrollToSectionWithOffset(id) {
      const element = document.querySelector(id);
      if (element) {
          // Hitung offset dengan 7vh dan 2rem
          const vhOffset = window.innerHeight * 0.07; // 7% dari tinggi viewport
          const remOffset = parseFloat(getComputedStyle(document.documentElement).fontSize) * 2; // 2rem dalam pixel
          const totalOffset = -(vhOffset + remOffset); // Jarak kombinasi negatif (ke atas)

          const yPosition = element.getBoundingClientRect().top + window.pageYOffset + totalOffset;

          window.scrollTo({ top: yPosition, behavior: 'smooth' });
      }
  }

  // Periksa jika URL mengandung hash (#) saat halaman dimuat
  if (window.location.hash) {
      setTimeout(function() {
          scrollToSectionWithOffset(window.location.hash);
      }, 500); // Delay untuk memastikan halaman sudah selesai dimuat
  }

  // Tangkap perubahan hash di URL (misalnya saat pengguna scroll ke section di halaman yang sama)
  window.addEventListener('hashchange', function() {
      scrollToSectionWithOffset(window.location.hash);
  });
});



var swiper = new Swiper(".mySwiperHero", {
    navigation: {
      nextEl: ".swiper-button-next-hero",
      prevEl: ".swiper-button-prev-hero",
    },
});

var swiper = new Swiper(".mySwiperRoom", {
    slidesPerView: 1,
    spaceBetween: 10,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next-room",
      prevEl: ".swiper-button-prev-room",
    },
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
});

var swiper = new Swiper(".mySwiperActivities", {
    slidesPerView: 1,
    spaceBetween: 10,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next-act",
      prevEl: ".swiper-button-prev-act",
    },
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 10,
      },
    },
});

var swiper = new Swiper(".mySwiperDining", {
  slidesPerView: 1,
  spaceBetween: 10,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next-dining",
    prevEl: ".swiper-button-prev-dining",
  },
  breakpoints: {
    640: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
});

var swiper = new Swiper(".mySwiperOffer", {
  slidesPerView: 1,
  spaceBetween: 10,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next-offer",
    prevEl: ".swiper-button-prev-offer",
  },
  breakpoints: {
    640: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
  },
});


